export const images = [
  {file: "20210323_160507",
  "thumbnail":"20210323_160507_thumb",
  "size":2,
  "name":"UNDER THE SEA",
  "description":"This outdoor mural took me 31 hours to complete, using exterior Valspar latex paint and a sealant on a wall measuring 10x18 feet. This big turtle comes zooming right out of the wall. My goal is to bring joy to the community through my artwork.",
  "price":null},
  {"file": "444222",
  "thumbnail":"444222_thumb",
  "size":1,
  "name":"PALM TREE BEACH",
  "description":"The inspiration for this 24x36 inch acrylic on canvas came to me when I sat at a picnic table along the Intracoastal in 2018. As I stared at the northern tip of a barrier island, a feeling of tranquility came upon me that hot August morning. The sand glistened and the sound of cicadas came from the nearby woods. I hope the viewer feels the stillness of that moment in this piece.",
  "price":100},
  {"file": "555333",
  "thumbnail":"555333_thumb",
  "size":1,
  "name":"SUNRISE BEACH",
  "description":"During the 2020 year of Covid-19, I spent each morning walking along the beach. This 24x36 inch acrylic on canvas was born after numerous observations I did of the sunrise along the ocean. The beach has become my sanctuary, where I feel relaxed and safe.",
  "price":100},
  {"file":"666777",
  "thumbnail":"666777_thumb",
  "size":1,
  "name":"ANGEL OYSTER SHELL ORNAMENTS",
  "description":"Oyster shells collected from the sea make ideal angel ornaments. I use a variety of acrylic inks and iridescent acrylic paints to tint the shells, then use E-6000 to glue on wooden doll heads, doll hair and embellishments. Each figure has a different face and make great holiday gifts!",
  "price":15},
  {"file":"20191224_131939",
  "thumbnail":"20191224_131939_thumb",
  "size":1,
  "name":"BREAKING WAVES WITH BIRDS",
  "description":"This acrylic on canvas measuring 36x40 inches depicts waves crashing along the beach during King Tide in 2019. I observed the flow of the tide in and out for several weeks until I could see it playing like a video in my head. The motion of the tiny birds along the waterline intrigued me.",
  "price":200},
  {"file":"20200806_123005",
  "thumbnail":"20200806_123005_thumb",
  "size":1,
  "name":"SHELL ABUNDANCE",
  "description":"Whenever I discover an abundance of shells along the shoreline, it appears like a feast at a dinner table set by the master. This mixed media acrylic on canvas measuring 24x24x1.5 inches is a close-up representation of low tide washing up the smallest of shells. I incorporated beach sand, assorted shells, acrylic paint and an acrylic finish in this piece.",
  "price":75},
  {"file":"20200418_160244",
  "thumbnail":"20200418_160244_thumb",
  "size":1,
  "name":"OCEAN FORCE",
  "description":"I understood the meaning of powerlessness when I began to study the energy and motion of the waves. The repetitive movement and sound of breaking waves assures me that all is well no matter how it appears to be. This acrylic on canvas measures 36x40x1.6 inches.",
  "price":200},
  {"file":"Palm Tree Mural May 2021",
  "thumbnail":"Palm Tree Mural May 2021_thumb",
  "size":1,
  "name":"TROPICAL YELLOW",
  "description":"My neighbor asked me to create this tropical scene over an exterior patio wall she had painted bright yellow. I used Valspar exterior latex paint and an acrylic finish on this wall measuring 10x16 feet over a period of 29 hours. She plans to put a tiki bar on her patio to complete her tropical paradise!",
  "price":null},
  {"file":"20210707_130746",
  "thumbnail":"20210707_130746_thumb",
  "size":1,
  "name":"ON THE GREEN",
  "description":"I was commissioned to paint this indoor mural in the clubhouse of my community. This is the 18th Signature Hole at the golf course, known for its difficulty due to the placement of the palm trees along the green. I used a variety of Valspar interior acrylic  shades over a period of 38 hours on a wall measuring 7x20 feet to create a majestic feeling.",
  "price":null}

];
