import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Gallery } from "./Gallery";
import { SingleImage } from "./SingleImage";
import App from "./App";

import "./styles.css";

// Get Framer Motion https://framer.com/motion

const app = (

    <App />

);

const rootElement = document.getElementById("root");
ReactDOM.render(app, rootElement);
