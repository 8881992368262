import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "./images";
import {
  Button,
  Container,
  Header,
  Grid,
  Image,
  Modal,
  Icon
} from "semantic-ui-react";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
};

const imageVariants = {
  exit: { y: "50%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition
  }
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 1, ...transition } }
};

const descriptionVariants = {
  exit: { y: "50%", opacity: 0, transition },
  enter: { y: "0%", opacity: 1, transition: { delay: 1, ...transition } }
};

export const SingleImage = ({ match }) => (

    <motion.div className="single" initial="exit" animate="enter" exit="exit">

      <motion.img
        variants={imageVariants}
        src={`${process.env.PUBLIC_URL}/assets/images/${
          images[parseInt(match.params.id, 10)].file
        }.jpg`}
        alt="Ann Frith Art"
      />
  </motion.div>

);

export const BackButton = () => (
<React.Fragment>
    <motion.div initial="exit" animate="enter" exit="exit">
    <motion.div className="back" variants={backVariants}>

      <Link to="/" className="back-text">← Back</Link>

    </motion.div>
    </motion.div>
</React.Fragment>


);

export const OfferModal = (props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
      trigger={<Button>Basic Modal</Button>}
    >
      <Header icon>
        <Icon name='archive' />
        Archive Old Messages
      </Header>
      <Modal.Content>
        <p>
          Your inbox is getting full, would you like us to enable automatic
          archiving of old messages?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' inverted onClick={() => setOpen(false)}>
          <Icon name='remove' /> No
        </Button>
        <Button color='green' inverted onClick={() => setOpen(false)}>
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export function ButtonLabel (id) {
  const price = images[parseInt(id, 10)].price
  if (price){
    return "Make Offer"
  }
  {
    return "Request Quote"
  }
}

export const Description = ({ match }) => (
  <React.Fragment>
  <motion.div initial="exit" animate="enter" exit="exit">

    <motion.div  variants={descriptionVariants}>
    <Container textAlign='left' className="description">
    <Header as="h2" style={{color:'#FFFFFF' }}>{images[parseInt(match.params.id, 10)].name} </Header>

    <p>
      {images[parseInt(match.params.id, 10)].description}
      </p>
      {images[parseInt(match.params.id, 10)].price && (
      <p>
        $ {images[parseInt(match.params.id, 10)].price}
      </p>
    )}
    <Button href="mailto:ann@annfrithart.com">{ButtonLabel(match.params.id)}</Button>

    </Container>

    </motion.div>
    </motion.div>
</React.Fragment>


);

export class SinglePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showModal: false  };

  }

  componentDidMount() {

    console.log(this.props)
  }

  handleModalOpen = () => {
      this.setState({ showModal: true });
      console.log(this.state.showModal)
  }

  render() {

    const { showModal  } = this.state;

    return (


    <Grid columns={2} stackable>
    <Grid.Row>
    <BackButton/>
</Grid.Row>
<Grid.Row>
    <Grid.Column >

    <SingleImage {...this.props}/>

    </Grid.Column >
    <Grid.Column verticalAlign='middle'>
    <Description {...this.props}/>
    </Grid.Column>

    </Grid.Row>
    </Grid>


  )}
}
