import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "./images";
import {
  Button,
  Container,
  Header,
  Grid,
  Icon,
  List
} from "semantic-ui-react";
const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 1.5, ...transition }
  }
};

const frameVariants = {
  hover: { scale: 0.95 }
};

const imageVariants = {
  hover: { scale: 1.1 }
};

const Thumbnail = (props) => (


  <motion.div className="thumbnail" variants={thumbnailVariants}>
    <motion.div
      className="frame"
      whileHover="hover"
      variants={frameVariants}
      transition={transition}
    >
      <Link to={`/image/${props.i}`} {...props}>
        <motion.img
          src={`${process.env.PUBLIC_URL}/assets/images/${props.id}.jpg`}
          alt="Ann Frith Art"
          variants={imageVariants}
          transition={transition}
        />

      </Link>

    </motion.div>
  </motion.div>
);

export const Gallery = () => (

  <>

    <h1 className="header-email"> Ann Frith Art</h1>


    <div className="gallery">
      <motion.div
        className="thumbnails"
        initial="initial"
        animate="enter"
        exit="exit"
        variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
      >
        {images.map((image, i) => (
          <React.Fragment>

          <Thumbnail key={image} id={image.thumbnail} i={i} name={image.name} desc={image.description}/>
          </React.Fragment>
        ))}
      </motion.div>
    </div>

  </>
);

export class FrontPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };

  }

  render() {

    return (

    <div className="main-container">
    <Grid>
    <Grid.Row centered>

    <List horizontal inverted className='social-list'>
              <List.Item as="a" href="mailto:ann@annfrithart.com">
                <Icon name='mail' size='huge' link className="contact-icon"/>
              </List.Item>
              <List.Item as="a" href="https://www.facebook.com/create67/">
                <Icon name='facebook' size='huge' link className="contact-icon"/>
              </List.Item>
            </List>


    </Grid.Row>
    <Grid.Row>
    <Container>
    <Gallery/>
    </Container>
    </Grid.Row>
    </Grid>
    </div>

  )}
}
