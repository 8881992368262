import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FrontPage } from "./Gallery";
import { SinglePage } from "./SingleImage";
import "semantic-ui-css/semantic.min.css";
import "./styles.css";
import {
  Button,
  Container,
  Header,
  Grid
} from "semantic-ui-react";
import { Menu } from "./Menu";
// Get Framer Motion https://framer.com/motion

const App = () => (
  <Router>

    <Route
      render={({ location }) => (
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/" component={FrontPage} />
            <Route exact path="/image/:id" component={SinglePage} />
          </Switch>
        </AnimatePresence>
      )}
    />
  </Router>
);

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default App
